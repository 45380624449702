import React from "react";
export default props => {
  return (
    // <svg 
    //     xmlns="http://www.w3.org/2000/svg" 
    //     className="arrow-icon"
    //     viewBox="0 0 40.996 25.556"
    // >
    //     <g id="Group_160" data-name="Group 160" transform="translate(-138.004 -100)">
    //         <rect id="Rectangle_119" data-name="Rectangle 119" width="37" height="4" transform="translate(142 111)" fill="none"
    //       stroke={props.color} strokeMiterlimit="10"
    //       strokeWidth="4"/>
    //         <g id="Group_159" data-name="Group 159" transform="translate(-68 83.061)">
    //         <rect id="Rectangle_120" data-name="Rectangle 120" width="18" height="4" transform="translate(206.004 29.667) rotate(-45)" fill="none"
    //       stroke={props.color} strokeMiterlimit="10"
    //       strokeWidth="4"/>
    //         <rect id="Rectangle_121" data-name="Rectangle 121" width="18" height="4" transform="translate(208.833 26.939) rotate(45)" fill="none"
    //       stroke={props.color} strokeMiterlimit="10"
    //       strokeWidth="4"/>
    //         </g>
    //     </g>
    // </svg>


    <svg xmlns="http://www.w3.org/2000/svg" width="40.996" height="25.556" viewBox="0 0 40.996 25.556" className="arrow-icon">
    <g id="Group_160" data-name="Group 160" transform="translate(-138.004 -100)">
        <rect id="Rectangle_119" data-name="Rectangle 119" width="37" height="4" transform="translate(142 111)" fill={props.color}/>
        <g id="Group_159" data-name="Group 159" transform="translate(-68 83.061)">
        <rect id="Rectangle_120" data-name="Rectangle 120" width="18" height="4" transform="translate(206.004 29.667) rotate(-45)" fill={props.color}/>
        <rect id="Rectangle_121" data-name="Rectangle 121" width="18" height="4" transform="translate(208.833 26.939) rotate(45)" fill={props.color}/>
        </g>
    </g>
    </svg>

  );
};
