import React from "react";
import styled from "styled-components";

const StyledGroup = styled.div`

  #multi_item {
    path {
      fill: ${props => props.color};
      @media screen and (max-width: 560px) {
        fill: ${props => props.color};
      }
      @media screen and (min-width: 561px) {
        fill: #030504
      }
    }
  }
`

export default props => {
  return (
    <StyledGroup color={props.color}>
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27.13 21.412"
    id="multiIcon"
  > 
    <g id="multi_item">
      <path
        d="M-34.44,300.4H-51.774V288.78a2.939,2.939,0,0,1,2.935-2.935h17.333V297.46a2.916,2.916,0,0,1-.86,2.075A2.916,2.916,0,0,1-34.44,300.4Zm-16.507-.827H-34.44a2.093,2.093,0,0,0,1.49-.617,2.093,2.093,0,0,0,.618-1.491V286.672H-48.839a2.111,2.111,0,0,0-2.108,2.108Z"
        transform="translate(51.774 -278.984)"
      />
      <path
        d="M-19.761,285.951H-20.9v-.827h1.144a1.876,1.876,0,0,0,1.874-1.874V272.227H-34.628A1.876,1.876,0,0,0-36.5,274.1v1.144h-.827V274.1a2.7,2.7,0,0,1,2.7-2.7H-17.06v11.85A2.7,2.7,0,0,1-19.761,285.951Z"
        transform="translate(40.76 -267.969)"
      />
      <path
        d="M-5.316,271.507H-6.46v-.827h1.144a1.876,1.876,0,0,0,1.874-1.873V257.783H-20.183a1.876,1.876,0,0,0-1.874,1.874V260.8h-.827v-1.144a2.7,2.7,0,0,1,2.7-2.7H-2.615v11.85A2.7,2.7,0,0,1-5.316,271.507Z"
        transform="translate(29.746 -256.956)"
      />
      <path
        d="M-27.349,317.651a2.98,2.98,0,0,1-2.977-2.977,2.98,2.98,0,0,1,2.977-2.977,2.98,2.98,0,0,1,2.977,2.977A2.98,2.98,0,0,1-27.349,317.651Zm0-5.126a2.152,2.152,0,0,0-2.15,2.149,2.152,2.152,0,0,0,2.15,2.15,2.152,2.152,0,0,0,2.15-2.15A2.152,2.152,0,0,0-27.349,312.524Z"
        transform="translate(35.42 -298.696)"
      />
      <path
        d="M-3.306,306.153H-8.741v-5.435h5.435Zm-4.608-.827h3.781v-3.78H-7.914Z"
        transform="translate(18.962 -290.324)"
      />
    </g>
  </svg>
  </StyledGroup>
  );
};
