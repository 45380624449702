import React from "react";
export default props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.414 29.414">
    <g
    >
      <line x2="30" fill="none" stroke="#fff" strokeWidth="2" />
      <line x2="20" fill="none" stroke="#fff" strokeWidth="2" />
      <line x2="10" fill="none" stroke="#fff" strokeWidth="2" />
    </g>
  </svg>
  );
};
